import 'lazysizes'
import Alpine from 'alpinejs'
import Collapse from '@alpinejs/collapse'
import Accordion from './alpine_components/accordion'
import { Iodine } from '@kingshott/iodine'
import debounce from 'lodash.debounce'

window.Iodine = new Iodine()

window.Alpine = Alpine
Alpine.data('accordion', Accordion)
Alpine.plugin(Collapse)
Alpine.start()


const HEADER_STICKY_CSS = 'is-sticky'
const $pageHeader = document.querySelector('.page-header')

const $primaryMobileNav = document.getElementById(('js-primary-mobile-nav'))

let menuIsOpen = false

// Sticky Header
window.addEventListener('scroll', function (e) {
  if (window.pageYOffset > 32) {
    $pageHeader.classList.add(HEADER_STICKY_CSS)
  } else {
    $pageHeader.classList.remove(HEADER_STICKY_CSS)
  }
})


// implement vh fix for mobile browsers
const recalculateVh = () => {
  let vh = window.innerHeight * 0.01
  document.documentElement.style.setProperty('--vh', `${vh}px`)
}

window.addEventListener('resize', debounce(recalculateVh, 330))
recalculateVh()




if (document.querySelector(".btn-menu")) {

  var mobileNav = document.querySelector(".primary-mobile-nav");
  var btnMenu = document.querySelector(".btn-menu");
  var body = document.querySelector("body");

  btnMenu.setAttribute('aria-label', btnMenu.dataset.openLabel)

  btnMenu.addEventListener("click", function () {

    if(this.menuIsOpen) {
      closeMenu()
    } else {
      openMenu()
    }

  }, false);
}

const openMenu = () => {
  btnMenu.classList.add("is-open");
  body.classList.add("is-fixed");
  body.classList.add("show-menu");
  btnMenu.setAttribute('aria-label', btnMenu.dataset.closeLabel)
}

const closeMenu = () => {
  btnMenu.classList.remove("is-open");
  body.classList.remove("is-fixed");
  body.classList.remove("show-menu");
  btnMenu.setAttribute('aria-label', btnMenu.dataset.openLabel)
}


const $overlay = document.querySelector('.js-overlay');
if ($overlay) {
  // Show overlay initially
  $overlay.classList.remove('hidden')

  $overlay.addEventListener('click', (e) => {
    if (e.target.tagName.toLowerCase() !== 'img') {
      $overlay.classList.add('hidden')
    }
  })

  const _hideOverlay = (e) => {
    $overlay.classList.add('hidden')
  }

  window.addEventListener('scroll', debounce(_hideOverlay, 330))
}
