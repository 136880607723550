export default (id) => {
  return {
    id: id,
    get expanded() {
      return this.active === this.id
    },
    set expanded(value) {
      this.active = value ? this.id : null
    },
  }
}
